<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 14 }" :wrapper-col="{ span: 10 }">
        <a-row>
          <a-col :span="24">
            <a-form-item label="运单号" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
              <a-input
                v-decorator="[
                  'tracking_number',
                  {
                    rules: [
                      { required: true, message: '请输入运单号' },
                      { max: 128, message: '超出最大长度(128)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="最小重量(kg)">
              <a-input-number
                v-decorator="[
                  'min_weight',
                  {
                    rules: [{ required: true, message: '请输入最小重量' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="最大重量(kg)">
              <a-input-number
                v-decorator="[
                  'max_weight',
                  {
                    rules: [{ required: true, message: '请输入最大重量' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="最小长度(mm)">
              <a-input-number
                v-decorator="[
                  'min_length',
                  {
                    rules: [{ required: true, message: '请输入最小长度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="最大长度(mm)">
              <a-input-number
                v-decorator="[
                  'max_length',
                  {
                    rules: [{ required: true, message: '请输入最大长度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="最小宽度(mm)">
              <a-input-number
                v-decorator="[
                  'min_width',
                  {
                    rules: [{ required: true, message: '请输入最小宽度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="最大宽度(mm)">
              <a-input-number
                v-decorator="[
                  'max_width',
                  {
                    rules: [{ required: true, message: '请输入最大宽度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="最小高度(mm)">
              <a-input-number
                v-decorator="[
                  'min_height',
                  {
                    rules: [{ required: true, message: '请输入最小高度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="最大高度(mm)">
              <a-input-number
                v-decorator="[
                  'max_height',
                  {
                    rules: [{ required: true, message: '请输入最大高度' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { standardPackageCreate } from "@/apis/calibration";

export default {
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          standardPackageCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
